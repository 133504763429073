import React, { useState } from 'react';
import { Container, TextField, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a custom theme with full-width container
const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '100% !important',
          padding: '0 24px',
        },
      },
    },
  },
});

// Helper function to format numbers with commas
const formatWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function App() {
  const [inputs, setInputs] = useState({
    issueAmount: '100,000,000',
    interestRate: 5.000,
    issueDate: '2024-01-01',
    firstInterestDate: '2024-07-01',
    maturityDate: '2034-01-01',
    interestFrequency: 'Semi-Annual',
    approxYield: 4.99999999999998,
    dayCountBasis: 0,
  });

  const [amortizationSchedule, setAmortizationSchedule] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'issueAmount') {
      // Remove non-digit characters and parse to number
      const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      
      // Format the number with commas
      const formattedValue = isNaN(numericValue) 
        ? '' 
        : numericValue.toLocaleString('en-US', {
            maximumFractionDigits: 0,
            useGrouping: true
          });
      
      setInputs(prev => ({ ...prev, [name]: formattedValue }));
    } else if (name === 'interestRate') {
      // Store the rate as entered (e.g., 5 for 5%)
      setInputs(prev => ({ ...prev, [name]: parseFloat(value) }));
    } else if (name === 'approxYield' || name === 'dayCountBasis') {
      setInputs(prev => ({ ...prev, [name]: parseFloat(value) }));
    } else {
      setInputs(prev => ({ ...prev, [name]: value }));
    }
  };

  const calculateDays360 = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const y1 = d1.getFullYear();
    const y2 = d2.getFullYear();
    const m1 = d1.getMonth();
    const m2 = d2.getMonth();
    const dd1 = d1.getDate();
    const dd2 = d2.getDate();
    return (y2 - y1) * 360 + (m2 - m1) * 30 + Math.min(30, dd2) - Math.min(30, dd1);
  };

  const calculateYearFraction = (startDate, endDate, basis) => {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1; // Month is 0-indexed
    const startDay = startDate.getDate();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    const endDay = endDate.getDate();

    let daysDiff;
  
    switch (basis) {
      case 0: // 30/360 (US or NASD)
        return (360 * (endYear - startYear) + 30 * (endMonth - startMonth) + (endDay - startDay)) / 360;
      case 1: // Actual/Actual
        const isLeapYear = year => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        
        if (startYear === endYear) {
          const daysInYear = isLeapYear(startYear) ? 366 : 365;
          return (endDate - startDate) / (daysInYear * 24 * 60 * 60 * 1000);
        } else {
          let fraction = 0;
          const fullYearsBetween = endYear - startYear - 1;
          
          // Days in start year
          const daysInStartYear = isLeapYear(startYear) ? 366 : 365;
          fraction += (new Date(startYear + 1, 0, 1) - startDate) / (daysInStartYear * 24 * 60 * 60 * 1000);
          
          // Full years
          for (let year = startYear + 1; year < endYear; year++) {
            fraction += 1 / (isLeapYear(year) ? 366 : 365);
          }
          
          // Days in end year
          const daysInEndYear = isLeapYear(endYear) ? 366 : 365;
          fraction += (endDate - new Date(endYear, 0, 1)) / (daysInEndYear * 24 * 60 * 60 * 1000);
          
          return fraction;
        }
      case 2: // Actual/360
        daysDiff = Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24); // Calculate days difference
        return daysDiff / 360;
      case 3: // Actual/365
        daysDiff = Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24); // Calculate days difference
        return daysDiff / 365;
      case 4: // European 30/360
        let d1 = startDate.getDate();
        let d2 = endDate.getDate();
        let m1 = startDate.getMonth() + 1;
        let m2 = endDate.getMonth() + 1;
        let y1 = startDate.getFullYear();
        let y2 = endDate.getFullYear();

        // Adjust for last day of February
        if (d1 === 31) d1 = 30;
        if (d2 === 31) d2 = 30;

        // Calculate the difference
        const yearFraction = (360 * (y2 - y1) + 30 * (m2 - m1) + (d2 - d1)) / 360;
        return yearFraction;
      default:
        throw new Error("Invalid basis value. Please use 0, 1, 2, 3, or 4.");
    }
  }

  const calculateAmortizationSchedule = () => {
    const { issueAmount, interestRate, issueDate, maturityDate, dayCountBasis, interestFrequency } = inputs;

    const rate = interestRate / 100;
    const approxYield = inputs.approxYield / 100;
    const startDate = new Date(issueDate);
    const endDate = new Date(maturityDate);

    // Calculate the number of years between issue date and maturity date
    const yearsDifference = endDate.getFullYear() - startDate.getFullYear();

    // Calculate the number of payment schedules based on interest frequency
    let numberOfSchedules;
    if (interestFrequency === 'Semi-Annual') {
      numberOfSchedules = yearsDifference * 2;
    } else if (interestFrequency === 'Annual') {
      numberOfSchedules = yearsDifference;
    } else {
      throw new Error('Invalid interest frequency');
    }

    const lastFourNumbers = Array.from({ length: 4 }, (_, i) => 
      (yearsDifference - 3 + i).toFixed(2)
    ).reverse();

    let schedule = [];
    let balance = parseFloat(issueAmount.replace(/,/g, ''));
    const repaymentAmount = balance / 4; // Calculate repayment amount
    let currentDate = new Date(endDate);
    let rowNumber = numberOfSchedules; // Start from the calculated number of schedules
    let nextInterest = 0; // Initialize nextInterest

    while (currentDate >= startDate) {
      let no;
      if (interestFrequency === 'Semi-Annual') {
        no = (rowNumber / 2).toFixed(2);
      } else {
        no = rowNumber.toFixed(2);
      }
      
      // Determine if this is one of the repayment schedules
      // const isRepaymentSchedule = ['7.00', '8.00', '9.00', '10.00'].includes(no);
      const isRepaymentSchedule = lastFourNumbers.includes(no);

      const repayment = isRepaymentSchedule ? repaymentAmount : 0;
      
      const yearfrac = calculateYearFraction(startDate, currentDate, dayCountBasis) * 2;
      const days360 = calculateDays360(startDate, currentDate) / 180;
      const pvFactorDays360 = 1 / Math.pow((1 + approxYield / 2), days360);
      const pvFactorYearfrac = 1 / Math.pow((1 + approxYield / 2), yearfrac);

      // Calculate interest
      const interest = (rate * repayment / 2) + nextInterest;
      nextInterest = interest; // Prepare nextInterest for the next iteration

      const row = {
        no: no,
        date: currentDate.toISOString().split('T')[0],
        interestRate: (rate * 100).toFixed(3) + '%',
        interest: formatWithCommas(interest.toFixed(2)),
        interestDebtBalance: formatWithCommas(interest.toFixed(2)), // Corrected as per your instruction
        repayment: formatWithCommas(repayment.toFixed(2)),
        debtBalance: balance.toFixed(2), // This will be adjusted later
        interestPlusRepayment: formatWithCommas((interest + repayment).toFixed(2)),
        pvFactorDays360: pvFactorDays360.toFixed(6),
        yearfrac: yearfrac.toFixed(6),
        pvFactorYearfrac: pvFactorYearfrac.toFixed(6),
        days360: days360.toFixed(0),
      };

      schedule.unshift(row); // Add to the beginning of the array

      balance += repayment; // Add repayment back to balance as we're going backwards
      
      
      if (interestFrequency === 'Semi-Annual') {
        // Move back 6 months
        currentDate.setMonth(currentDate.getMonth() - 6);
      } else {
        // Move back 1 year
        currentDate.setFullYear(currentDate.getFullYear() - 1);
      }
      
      // Adjust for end of month issues
      if (currentDate.getDate() !== endDate.getDate()) {
        currentDate.setDate(0); // Last day of the previous month
        currentDate.setDate(Math.min(endDate.getDate(), currentDate.getDate()));
      }
      
      rowNumber--;
    }

    // Adjust the Debt Balance
    let previousDebtBalance = parseFloat(issueAmount.replace(/,/g, ''));
    schedule.forEach(row => {
      const newDebtBalance = previousDebtBalance - parseFloat(row.repayment.replace(/,/g, ''));
      row.debtBalance = formatWithCommas(newDebtBalance.toFixed(2));
      previousDebtBalance = newDebtBalance;
    });

    // Adjust the first row (issue date)
    if (schedule.length > 0) {
      schedule[0].interest = '0.00';
      schedule[0].interestDebtBalance = '0.00';
      schedule[0].interestPlusRepayment = '0.00';
      schedule[0].debtBalance = issueAmount;
    }

    // Calculate summary
    const totalRepayment = schedule.reduce((sum, row) => sum + parseFloat(row.repayment.replace(/,/g, '')), 0);
    const totalInterest = schedule.reduce((sum, row) => sum + parseFloat(row.interest.replace(/,/g, '')), 0);

    setAmortizationSchedule([...schedule, {
      date: 'Total',
      interest: formatWithCommas(totalInterest.toFixed(2)),
      repayment: formatWithCommas(totalRepayment.toFixed(2)),
      interestPlusRepayment: formatWithCommas((totalRepayment + totalInterest).toFixed(2)),
    }]);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <div style={{ maxWidth: '1600px', margin: '0 auto' }}>
          <Typography variant="h4" gutterBottom>
            Debt Calculator
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Issue Amount"
                name="issueAmount"
                value={inputs.issueAmount}
                onChange={handleChange}
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Interest Rate (%)"
                name="interestRate"
                value={inputs.interestRate}
                onChange={handleChange}
                fullWidth
                type="number"
                InputProps={{
                  inputProps: { 
                    step: 0.001,
                    min: 0,
                    max: 100,
                    style: { textAlign: 'left' }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Issue Date"
                name="issueDate"
                type="date"
                value={inputs.issueDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="First Interest Date"
                name="firstInterestDate"
                type="date"
                value={inputs.firstInterestDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Maturity Date"
                name="maturityDate"
                type="date"
                value={inputs.maturityDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel id="interest-frequency-label">Interest Frequency</InputLabel>
                <Select
                  labelId="interest-frequency-label"
                  name="interestFrequency"
                  value={inputs.interestFrequency}
                  onChange={handleChange}
                  label="Interest Frequency"
                >
                  <MenuItem value="Semi-Annual">Semi-Annual</MenuItem>
                  <MenuItem value="Annual">Annual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Approx. Yield (%)"
                name="approxYield"
                value={inputs.approxYield}
                onChange={handleChange}
                fullWidth
                type="number"
                inputProps={{ step: 0.001 }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel id="dayCountBasis-label">Daycount Basis</InputLabel>
                <Select
                  labelId="dayCountBasis-label"
                  id="dayCountBasis-select"
                  name="dayCountBasis"
                  value={inputs.dayCountBasis}
                  onChange={handleChange}
                  label="Daycount Basis"
                >
                  <MenuItem value={0}>0 - US (NASD) 30/360</MenuItem>
                  <MenuItem value={1}>1 - Actual/actual</MenuItem>
                  <MenuItem value={2}>2 - Actual/360</MenuItem>
                  <MenuItem value={3}>3 - Actual/365</MenuItem>
                  <MenuItem value={4}>4 - European 30/360</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={calculateAmortizationSchedule}
                fullWidth
                sx={{
                  height: '56px', // Standard height of MUI text fields
                  '&.MuiButton-root': {
                    textTransform: 'none', // Optional: prevents all-caps text
                  }
                }}
              >
                Calculate
              </Button>
            </Grid>
          </Grid>
          

          {amortizationSchedule.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Interest Rate</TableCell>
                    <TableCell>Interest</TableCell>
                    <TableCell>Interest/(Debt Balance)</TableCell>
                    <TableCell>Repayment</TableCell>
                    <TableCell>Debt Balance</TableCell>
                    <TableCell>Interest + Repayment</TableCell>
                    <TableCell>PV Factor (days360)</TableCell>
                    <TableCell>Yearfrac</TableCell>
                    <TableCell>PV Factor (Yearfrac)</TableCell>
                    <TableCell>Days360</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {amortizationSchedule.map((row) => (
                    <TableRow key={row.no + row.date}>
                      <TableCell>{row.no}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.interestRate}</TableCell>
                      <TableCell>{row.interest}</TableCell>
                      <TableCell>{row.interestDebtBalance}</TableCell>
                      <TableCell>{row.repayment}</TableCell>
                      <TableCell>{row.debtBalance}</TableCell>
                      <TableCell>{row.interestPlusRepayment}</TableCell>
                      <TableCell>{row.pvFactorDays360}</TableCell>
                      <TableCell>{row.yearfrac}</TableCell>
                      <TableCell>{row.pvFactorYearfrac}</TableCell>
                      <TableCell>{row.days360}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default App;